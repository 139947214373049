import styled, { css } from 'styled-components';
import { colors, breakpoints, externalContentStyle } from '../../../../../constants/style';

// TODO Change hardcoded values to variables

const getColor = (props) => {
  if (props.isDragAccept) return '#00e676';
  if (props.isDragReject) return '#ff1744';
  if (props.isDragActive) return '#2196f3';

  return '#eeeeee';
};

const defaultFont = css`
  font-family: "BioSans-SemiBold", sans-serif;
`;

const inputBorder = css`
  border: 1px solid #c1c1d0;
`;

const formTextInput = css`
  .form__text-input {
    ${inputBorder}
    padding: 16px;
  }
  .form__text-input:focus {
    border: 1px solid ${colors.primary};
    outline: 0;
  }

  .form__text-input-error {
    margin-top: 4px;
    border: 1px solid #ee404f;
  }
`;

const customRadioBtn = css`
  .form__radio-btns-wrapper {
    display: flex;
    margin-top: 16px;
    overflow: hidden;
  }

  .form__radio-btns-wrapper input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  .form__radio-btns-wrapper label {
    background-color: white;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 20px 30px;
    margin-right: -1px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease-in-out;
  }

  .form__radio-btns-wrapper label:hover {
    cursor: pointer;
  }

  .form__radio-btns-wrapper input:checked + label {
    background-color: #00d69e;
    box-shadow: none;
    color: white;
  }

  .form__radio-btns-wrapper label:first-of-type {
    border-radius: 0;
  }

  .form__radio-btns-wrapper label:last-of-type {
    border-radius: 0;
  }
`;

const dropdownStyles = css`
  .form__dropdown-container > * {
    border-radius: 0;
    padding: 7px 0;
  }
  .form__dropdown__control {
    ${inputBorder}
    outline: 0;
    box-shadow: none;
  }
  .form__dropdown__control:focus {
    border: 1px solid ${colors.primary};
    outline: 0;
  }

  .form__dropdown__control--is-focused {
    border: 1px solid ${colors.primary};
    outline: 0;
    box-shadow: none;
  }
`;

const fakeSelectInputStyle = css`
  .form__fake-input-for-select {
    opacity: 0;
    width: 100%;
    height: 0;
    position: absolute;
  }
`;

const StyledApplyFormWrapper = styled.section`
  * {
    ${defaultFont}
  }
  ${customRadioBtn}

  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .form-content {
    background: white;
    width: 70%;
    padding: 5rem;
    margin: 0 auto;
    position: relative;
    margin-top: -7rem;
    display: flex;
    flex-direction: column;
    ${externalContentStyle};
    ${formTextInput}
    ${dropdownStyles}
    ${fakeSelectInputStyle}

    h1,
    h2,
    h3 {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }

    p,
    ul,
    ol {
      margin-bottom: 1.5rem;
    }

    p,
    li {
      font-size: 1rem;
    }
  }

  .form-content__sended-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form__input-required {
    color: ${colors.error};
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    .form-content {
      width: 100%;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .form-content {
      margin-top: -7rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding-bottom: 2rem;
    padding-top: 2rem;
    .form-content {
      padding: 1rem;
      margin-top: -10rem;
    }
  }

  .apply-form__radio-btns-wrapper {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }
`;

export const StyledLabel = styled.label`
  ${defaultFont}
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 8px;
`;

export const StyledListElem = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const StyledCheckboxInputWrapper = styled.label`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
    color: #42424c;
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #c1c1d0;
    border-radius: 2px;
    background-color: white;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
  input[type='checkbox']:checked ~ .checkmark {
    background-color: ${colors.primary};
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const StyledPhoneInputWrapper = styled.div`
  .input {
    ${inputBorder}
    width: 100%;
    border-radius: 0;
    margin: 16px 0;
    height: 52px;
  }

  .input:focus {
    border: 1px solid ${colors.primary};
  }

  .area-code-button {
    border-radius: 0;
  }
`;

export const StyledErrorMsg = styled.span`
  ${defaultFont}
  color: ${colors.error};
  font-size: 10px;
  line-height: 13px;
  margin-bottom: 8px;
`;

export const StyledDragAndDropContainer = styled.div`
  ${defaultFont}
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  cursor: pointer;
  text-align: center;
  transition: border 0.24s ease-in-out;

  .form__drag-container-purple-text {
    color: ${colors.primary};
  }

  .form__drag-container-img {
    margin-right: 8px;
  }

  .form__drag-container-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledLoadingIndicatorWrapper = styled.div`
  margin: 2vh auto;
  min-height: 80vh;
`;

export const StyledAxiosError = styled.div`
  margin: ${({ margin }) => (margin ? '10vh auto' : '2vh auto')};
  text-align: center;
  color: ${({ error }) => error && colors.error};

  a {
    color: ${colors.primary};
  }

  a:hover {
    color: ${colors.secondary};
  }
`;

export const StyledRejectedFile = styled.h5`
  color: ${colors.error};
  h5 {
    color: ${colors.primary};
  }
`;

export default StyledApplyFormWrapper;
