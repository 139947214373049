export const typeBasedValidation = (type = '') =>
  ({
    short_text: { // short text is type of input
      // this limit is restricted by workable
      // https://workable.readme.io/docs/job-candidates-create
      maxLength: {
        value: 128,
        message: 'This field can have maximum of 128 characters',
      },
    },
  }[type]);
