import React, { useMemo } from 'react'
import { graphql } from 'gatsby'

import Layout from '../../features/layout'
import SectionsContainer from 'components/SectionsContainer'
import { SEO } from 'components/Seo'

import 'hooks/graphql/section'
import 'hooks/graphql/others'
import 'hooks/graphql/blog'

export const query = graphql`
  query CustomPageQuery($id: String!, $blogCategoryId: String!) {
    sanityCustomPage(id: { eq: $id }) {
      shouldNavigationStartTransparent

      ...AllSectionsFragment

      _rawSections

      seo {
        ...SeoFragment
      }
    }

    postsByCategory: allSanityBlogPost(
      filter: { categories: { elemMatch: { id: { eq: $blogCategoryId } } } }
      limit: 6
      sort: { createdAt: DESC }
    ) {
      nodes {
        ...BlogPostFields
      }
    }
  }
`

const CustomPage = (props) => {
  const {
    location,
    data: { sanityCustomPage, postsByCategory },
  } = props
  const { shouldNavigationStartTransparent, sections, _rawSections, seo } = sanityCustomPage

  const sectionWithBlogPosts = useMemo(
    () =>
      sections.map((section) => {
        const blogPosts =
          section._type === 'articlesSection'
            ? [
                ...section.blogPosts.filter(({ __typename }) => __typename === 'SanityBlogPost'),
                ...postsByCategory.nodes,
              ]
            : null

        return { ...section, blogPosts }
      }),
    [sections],
  )
  return (
    <Layout shouldNavigationStartTransparent={shouldNavigationStartTransparent}>
      <SectionsContainer sections={sectionWithBlogPosts} _rawSections={_rawSections} />
    </Layout>
  )
}

export function Head({ location, data: { sanityCustomPage } }) {
  return <SEO location={location} {...sanityCustomPage.seo} />
}

export default CustomPage
