import axios from 'axios';

export const handlePost = async (formData) => {
  try {
    const { status, statusText } = await axios.post('/api/career-survey-form', formData);
    return { status, statusText };
  } catch (err) {
    return err;
  }
};
