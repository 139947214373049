import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { colors, breakpoints } from '../../../../../constants/style';

// TODO Change hardcoded values to variables

const defaultFont = css`
  font-family: "BioSans-SemiBold", sans-serif;
`;

const StyledThankYouSection = styled.main`
  * {
    ${defaultFont}
  }
  padding: 96px 0;

  .thank-you__title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  header {
    background-color: ${colors.background};
    padding: 2.5rem 0;
    border-radius: 8px;
    box-shadow: 4px 24px 40px rgb(226 227 233 / 5%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
  }

  .thank-you__survey-button {
    margin-top: 2rem;
  }

  .thank-you__video-wrapper {
    width: 70%;
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    .thank-you-content {
      padding: 1rem;
      width: 100%;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopMac}) {
    .thank-you__title {
      font-size: 36px;
    }
    header {
      margin: 0;
    }
  }

  @media only screen and (max-width ${breakpoints.tablet}) {
    .thank-you__video-wrapper {
      width: 100%;
    }
    .thank-you__video-wrapper {
      width: 90%;
    }
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 2rem;
    padding: 250px 0;

    .thank-you-content {
      padding: 1rem;
    }

    .thank-you__title {
      font-size: 18px;
    }

    header {
      margin: 2rem 0;
      height: -webkit-fill-available;
    }

    .thank-you__video-wrapper {
      width: 90%;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding-bottom: 2rem;
    padding: 150px 0;

    .thank-you-content {
      padding: 1rem;
    }

    .thank-you__title {
      font-size: 18px;
    }

    header {
      margin: 2rem 0;
      height: -webkit-fill-available;
      padding: 2rem 0;
    }

    .thank-you__video-wrapper {
      width: 95%;
    }
  }
`;

export const StyledLink = styled(Link)`
  color: ${colors.primary};
`;

export const StyledBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledCareerVideo = styled.video`
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 4px 24px 40px rgb(226 227 233 / 5%);
`;

export default StyledThankYouSection;
