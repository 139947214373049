import React from 'react'
import Fade from 'react-reveal/Fade'
import Image from '../../../../Image'

import { IconLink } from '../../../../Link'
import SanityBlockContent from '../../../../SanityBlockContent'
import { parseSlug } from '../../../../../utils/slug'

import CaseStudiesEntryWrapper from './styled'

import IconArrow from '../../../../icons/IconArrow'
import IconApple from '../../../../icons/IconApple'
import IconGooglePlay from '../../../../icons/IconGooglelPlay'
import { breakpoints } from '../../../../../../constants/style'

const CaseStudiesEntry = ({
  buttonLabel,
  caseStudy,
  description,
  downloadLabel,
  title,
  image: mainImage,
}) => {
  const { slug, _rawAbstract: rawAbstract } = caseStudy
  const { image, projectName, tags, imageBackgroundColorStart, imageBackgroundColorEnd, url } =
    caseStudy.abstract
  const { ios, android } = caseStudy.summary ? caseStudy.summary : { ios: null, android: null }
  const buttonUrl = url ? url : `/case-studies/${parseSlug(slug.current)}`
  return (
    <CaseStudiesEntryWrapper
      className="casestudies__item"
      imageBackgroundColorStart={imageBackgroundColorStart}
      imageBackgroundColorEnd={imageBackgroundColorEnd}
    >
      <Fade opposite>
        <div className="casestudies__text-wrapper">
          <h3 className="casestudies__item-header">
            {title?.length ? title[0].children[0].text : projectName}
          </h3>
          <SanityBlockContent
            className="casestudies__item-description"
            blocks={description?.length ? description[0] : rawAbstract.projectSummary}
          />
          {Boolean(tags) && (
            <div className="casestudies__item-tags">
              <div className="casestudies__item-tag--first">{tags[0]}</div>
              <div className="casestudies__item-tag--labels">
                {tags.map((tag, index) =>
                  index > 0 ? (
                    <div key={tag} className="casestudies__item-tag--label">
                      {tag}
                    </div>
                  ) : null,
                )}
              </div>
              <div className="casestudies__shadow">{tags[tags.length - 1]}</div>
            </div>
          )}
          <div className="casestudies__item-buttons">
            <IconLink to={buttonUrl} icon={<IconArrow />}>
              {buttonLabel}
            </IconLink>
            {Boolean(ios && ios.url) && (
              <IconLink to={ios.url} icon={<IconApple />} targetBlank>
                {downloadLabel}
              </IconLink>
            )}
            {Boolean(android && android.url) && (
              <IconLink to={android.url} icon={<IconGooglePlay />} targetBlank>
                {downloadLabel}
              </IconLink>
            )}
          </div>
        </div>
        <div className="casestudies__image-wrapper">
          <Image
            className="casestudies__image"
            alt={(mainImage || image)?.alt || `${projectName} case study image`}
            image={mainImage || image}
            sizes={`(max-width: ${breakpoints.desktop}) 100vw, 50vw`}
          />
        </div>
      </Fade>
    </CaseStudiesEntryWrapper>
  )
}

export default CaseStudiesEntry
