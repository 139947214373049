import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import propTypes from 'prop-types';
import Button from '../../../../components/Buttons';
import IconLoading from '../../../../components/icons/IconLoading';
import StyledMainSection, { StyledLoadingIndicatorWrapper, StyledPostErr } from './styled';
import { handlePost } from './utils/api';

/* This is implemented this way: 
https://medium.com/geekculture/link-your-html-form-to-a-spreadsheet-via-google-forms-9024f0611d82 
The actual axios post request is in /functions folder in root of 10c-site
*/

//   Form data
const ratingScale = [1, 2, 3, 4, 5];

const formQuestions = [
  { question: 'How do you rate the look and feel of the 10Clouds Careers page?', ratingScale, registerName: 'quality' },
  {
    question: "How do you rate the quality and depth of the information provided about the position you're applying for?",
    ratingScale,
    registerName: 'info',
  },
];

const SurveyForm = ({ onClick, setIsSurveySent, isFullscreen = false }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getFormData = ({ firstAnswer, secondAnswer }) => `entry.1616270078=${firstAnswer}&entry.1587757034=${secondAnswer}`;

  const onSubmit = async (data) => {
    setIsLoading(true);
    const formData = getFormData({ firstAnswer: data.quality, secondAnswer: data.info });

    try {
      const { status } = await handlePost(formData);
      if (status === 200) {
        setIsSurveySent && setIsSurveySent(true);
        setIsLoading(false);
        // closes the modal
        onClick();
      }
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const renderInputField = ({ question, ratingScale, registerName }) => (
    <article key={question}>
      <span>{question}</span>
      <main>
        {ratingScale.map((value) => (
          <label className="option" key={value}>
            <label>{value}</label>
            <input
              key={value}
              type="radio"
              value={value}
              {...register(registerName, {
                required: true,
              })}
            />
          </label>
        ))}
      </main>
      {errors[registerName] && <p className="form__input-required">This field is required</p>}
    </article>
  );

  return (
    <StyledMainSection isFullscreen={isFullscreen}>
      <h1>What do you think about our Carrer page?</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="form-content">
        {formQuestions.map(renderInputField)}
        {isLoading ? (
          <StyledLoadingIndicatorWrapper>
            <IconLoading />
          </StyledLoadingIndicatorWrapper>
        ) : (
          <Button type="submit" variant="filled">
            Send
          </Button>
        )}
        {isError && <StyledPostErr>Something went wrong, try again </StyledPostErr>}
      </form>

      <div className="close" onClick={onClick} />
    </StyledMainSection>
  );
};

SurveyForm.propTypes = {
  onClick: propTypes.func.isRequired,
  setIsSurveySent: propTypes.func,
};

SurveyForm.defaultProps = {
  setIsSurveySent: () => {},
};

export default SurveyForm;
