import React, { Suspense } from 'react';
import { NavLinkButton } from '../../Link';
import { contact } from '../../../../constants/links';
import { animateScroll as scroll } from 'react-scroll';
import Button from '../../Buttons';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import { themes } from './styled';
import { ContactUsSection, Blur } from './styled';
import Image from '../../Image';
import { checkLink } from '../../../utils/link';
import useWindowSize from '../../../hooks/useWindowSize';

const LIGHT_THEMES = ['blog', 'lightMiddle'];

/**
 * @param {('light'|'lightPurple'|'lightTolightPurple'|'lightPurpleToLight'|'dark'|'lightMiddle'|'lightToDark'|'lightPurpleToDark'|'lightPurpleToTitleDark'|'darkToLight')} theme Layout theme
 * @param header string Head line text
 * @param buttonLabel string Text for navigation button label
 * @param scrollTopOnClick boolean
 * @param description string
 * @param url string Can be empty or link or mailto or tel or querySelector
 * @param middle boolean If it's section in the middle of the page
 * @param shouldDisplayEmployee boolean
 * @param employee array, there can only be 1 employee
 */
const ContactUs = ({
  theme,
  header,
  buttonLabel,
  scrollTopOnClick = false,
  description,
  url,
  middle = false,
  shouldDisplayEmployee = false,
  employee = [],
}) => {
  const isLink = checkLink(url);
  const [employeeItem] = employee.length ? employee : [];
  const { isSmallerThanDesktop } = useWindowSize();
  const themeName = themes.hasOwnProperty(theme) ? theme : middle ? 'lightMiddle' : 'lightToDark';

  return (
    <>
      <Suspense fallback={<LoadingIndicator />}>
        <ContactUsSection theme={themes[themeName]} middle={middle} shouldDisplayEmployee={shouldDisplayEmployee}>
          <div className="container">
            <div className="contact-us">
              {shouldDisplayEmployee && (
                <div className="contact-us__left">
                  <Image image={employeeItem?.image} className="contact-us__employee-photo" />
                  <div className="contact-us__employee-name">{employeeItem?.name}</div>
                  <div className="contact-us__employee-position">{employeeItem?.position}</div>
                </div>
              )}
              <div className="contact-us__right">
                {!LIGHT_THEMES.includes(themeName) && <Blur />}
                {Boolean(header) && <h2 style={{ marginBottom: !description && '48px' }}>{header}</h2>}
                {Boolean(description) && <p>{description}</p>}
                {scrollTopOnClick ? (
                  <Button onClick={() => scroll.scrollToTop()} variant="filled">
                    {buttonLabel}
                  </Button>
                ) : url && !isLink ? (
                  <Button
                    onClick={() => {
                      // we remove 100px to generate offset for navbar
                      const navbarOffset = isSmallerThanDesktop ? 0 : 100;
                      scroll.scrollTo(document.querySelector(url).getBoundingClientRect().top - navbarOffset + window.pageYOffset);
                    }}
                    variant="filled"
                  >
                    {buttonLabel}
                  </Button>
                ) : (
                  <NavLinkButton to={Boolean(url?.startsWith('http')) ? url : contact.url} variant="filled">
                    {buttonLabel}
                  </NavLinkButton>
                )}
              </div>
            </div>
          </div>
        </ContactUsSection>
      </Suspense>
    </>
  );
};

export default ContactUs;
