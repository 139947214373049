import React, { useCallback, useEffect, useState, useRef, lazy } from 'react'
import { graphql, navigate } from 'gatsby'
import propTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import DatePicker from 'react-date-picker'

import { SEO } from 'components/Seo'
import StyledApplyFormWrapper, {
  StyledCheckboxInputWrapper,
  StyledDragAndDropContainer,
  StyledErrorMsg,
  StyledAxiosError,
  StyledLabel,
  StyledListElem,
  StyledLoadingIndicatorWrapper,
  StyledPhoneInputWrapper,
  StyledRejectedFile,
} from './styled'
import coreInputFieldsData from './coreInputFieldsData'
import 'react-phone-input-2/lib/style.css'

import 'react-phone-input-2/lib/style.css'
import Button from '../../../../components/Buttons'
import Layout from '../../../../features/layout'
import CareerHero from '../CareerHero'
import IconLoading from '../../../../components/icons/IconLoading'

import paperclip from '../../../../../static/images/icons/paperclip.svg'
import { useCareer } from 'hooks/graphql/page'
import { GA_CANDIDATE_DOMAIN_SOURCE, POSITION_PLACEHOLDER } from '../../constants'
import {
  dropdownPreInputName,
  questionsPreInputName,
  renderRequiredInputStar,
  requiredFieldMsg,
} from './utils/variables'
import {
  convertBytesInString,
  convertToBase64String,
  formatBytes,
  getCandidateSourcePage,
  getFormatedQuestionFormOutput,
  getKeyByValue,
  getRenamedKeysForDropdown,
  isUploadedFileValidator,
} from './helpers/functions'
import useWindowSize from '../../../../hooks/useWindowSize'
import { typeBasedValidation } from './utils/validation'
const PhoneInput = lazy(() => import('react-phone-input-2'))
const AsyncSelect = lazy(() => import('react-select/async'))
import 'hooks/graphql/others'

export const query = graphql`
  query CareerApplyFormQuery($id: String!) {
    ...workableJob
  }
`

const fetchApplicationFormData = async (workableShortcode) => {
  const { data } = await axios.get(`/api/career-apply-form`, { params: { workableShortcode } })
  return data
}

// Docuentation
// https://10clouds.atlassian.net/wiki/spaces/10CWWW/pages/2991554615/Workable+apply+form
const ApplyForm = ({ data, location: currentURL }) => {
  const { shortcode: workableShortcode, title: jobTitle, location: jobLocation } = data?.workableJob
  const { isSmallerThanDesktopSmall } = useWindowSize()
  const [apiData, setApiData] = useState(null)
  const [date, setDate] = useState()
  const [isResumeInputInApiData, setIsResumeInputInApiData] = useState()
  const [selectedValues, setSelectedValues] = useState([
    {
      registrationName: '',
      selectedOption: [],
    },
  ])
  console.log(data)

  const formRef = useRef()

  // Watching form submission
  const [loading, setLoading] = useState(false)
  const [isFetchError, setIsFetchError] = useState(false)
  const [isPostingDataError, setIsPostingDataError] = useState(false)
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    validator: isUploadedFileValidator,
    accept: '.pdf,.doc,.docx,.rtf',
    maxFiles: 1,
    multiple: false,
    maxSize: 5242880,
  })

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'all',
  })

  useEffect(() => {
    const isResumeInputExists = apiData?.form_fields.some(({ key }) => key === 'resume')
    setIsResumeInputInApiData(isResumeInputExists)
  }, [apiData]) // FIXME in dependency array should be only primitives

  useEffect(() => {
    fetchApplicationFormData(workableShortcode)
      .then(setApiData)
      .catch((err) => {
        console.error(err)
        setIsFetchError(true)
      })
  }, [workableShortcode])

  // manually registering custom input component
  useEffect(() => {
    register('phone')
    isResumeInputInApiData && register('resume')
    date && register('date')
  }, [register, date, isResumeInputInApiData])

  useEffect(() => {
    // When form is loaded scroll to it
    apiData &&
      window.scrollTo({ top: Number(formRef?.current?.offsetTop - 200), behavior: 'smooth' })
  }, [apiData])

  const onSubmit = async (data) => {
    const { firstname, lastname, phone, email, address, ...rest } = data

    if (acceptedFiles.length === 0 && isResumeInputInApiData) {
      setError('resume', { type: 'manual', message: 'You have to upload your CV' })
      return
    } else clearErrors('resume')

    setLoading(true)

    const answers = getFormatedQuestionFormOutput(rest, questionsPreInputName, dropdownPreInputName)
    const domain = getCandidateSourcePage(GA_CANDIDATE_DOMAIN_SOURCE)

    try {
      const formData = {
        sourced: false,
        candidate: {
          ...(address && { address }),
          name: `${firstname} ${lastname}`,
          firstname,
          lastname,
          headline: jobTitle,
          phone,
          email,
          ...(isResumeInputInApiData && {
            resume: {
              name: acceptedFiles[0].name,
              data: await convertToBase64String(acceptedFiles[0]),
            },
          }),
          domain,
          answers, // if you got 422 err code then in this variable are commons problems
        },
      }

      //  Posting user to workable api
      const { status } = await axios.post('/api/career-apply-form', formData, {
        params: { workableShortcode },
      })
      if (status === 201) {
        setLoading(false)

        // After succesfull post redirect user to thank you page
        navigate(currentURL.pathname.replace('apply', 'thank-you-for-apply'), { state: formData })
      }
    } catch (err) {
      setLoading(false)
      setIsPostingDataError(true)
      console.error(err)
    }
  }
  // Dropdown files info
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {formatBytes(file.size)}
    </li>
  ))

  // Dropdown Rejected files
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <StyledRejectedFile key={file.path}>
      {file.path} - {formatBytes(file.size)}
      {errors.map((e) => (
        <h5 key={e.code}>
          {convertBytesInString({
            charsBeforeCutedString: 'larger than',
            charsAfterCutedString: 'bytes',
            originalString: e.message,
          })}{' '}
        </h5>
      ))}
    </StyledRejectedFile>
  ))

  const handleDropdownSelect = useCallback(
    (selectedOption, registrationName, isRequired, originalChoices) => {
      clearErrors(registrationName)

      const [selectedOptionId] = originalChoices.filter((item) =>
        getKeyByValue(item, selectedOption.value),
      )

      const dropDownSelectedOptionId = `${dropdownPreInputName}${selectedOptionId.id}`

      if (isRequired && !selectedOption) {
        setValue(registrationName, dropDownSelectedOptionId)
        setError(registrationName, { type: 'manual', message: 'You have to select one option' })
        return
      }

      setValue(registrationName, dropDownSelectedOptionId)
      setSelectedValues((prev) =>
        prev.map((selected) =>
          selected.registrationName === registrationName
            ? { ...selected, selectedOption }
            : selected,
        ),
      )
    },
    [clearErrors, setError, setValue],
  )

  const checkboxValidation = useCallback(
    ({ inputNameKey: questionNameKey, isSingleAnswerRequired }) =>
      getValues(questionNameKey).length > 1 && isSingleAnswerRequired
        ? 'You have to select only one option'
        : getValues(questionNameKey).length
        ? true
        : 'You have to select at least one option',
    [getValues],
  )

  const renderCheckBoxInput = useCallback(
    ({ id: choiceId, body: choiceOptionName, inputNameKey, isSingleAnswerRequired, ...rest }) => (
      <StyledCheckboxInputWrapper key={choiceId}>
        <input
          type="checkbox"
          value={choiceId}
          id={`id-${choiceId}`}
          {...register(inputNameKey, {
            validate: () => checkboxValidation({ inputNameKey, isSingleAnswerRequired }),
          })}
        />
        <span className="checkmark" />
        <label htmlFor={`id-${choiceId}`}>{choiceOptionName}</label>
      </StyledCheckboxInputWrapper>
    ),
    [checkboxValidation, register],
  )

  const renderCoreInputFields = ({ inputName, label, options, type }) => (
    <StyledListElem key={inputName}>
      <StyledLabel htmlFor={inputName}>
        {label}
        {options?.required && renderRequiredInputStar}
      </StyledLabel>
      <input
        type={type}
        {...register(inputName, { ...options })}
        className={`form__text-input ${errors[inputName] && 'form__text-input-error'}`}
      />
      {errors[inputName] && (
        <StyledErrorMsg>{errors[inputName].message || requiredFieldMsg}</StyledErrorMsg>
      )}
    </StyledListElem>
  )

  const renderFormFields = ({ key, label, required, type, ...rest }) => (
    <StyledListElem key={key}>
      <StyledLabel htmlFor={key}>
        {key === 'resume' ? 'CV' : label} {required && renderRequiredInputStar}
      </StyledLabel>
      {type === 'file' ? (
        <>
          <StyledDragAndDropContainer {...getRootProps({ isDragActive })}>
            <input name={key} {...getInputProps()} />
            <div className="form__drag-container-wrapper">
              <img src={paperclip} className="form__drag-container-img" alt="Paperclip" />
              <span className="form__drag-container-purple-text">Upload file&nbsp;</span>
              {!isSmallerThanDesktopSmall && 'or drag and drop here'}
            </div>
          </StyledDragAndDropContainer>
          <aside>
            {!!files.length && (
              <>
                <span>Files</span>
                <ul>{files}</ul>
              </>
            )}
            {!!fileRejectionItems.length && (
              <>
                <span>Rejected file</span>
                <ul>{fileRejectionItems}</ul>
              </>
            )}
          </aside>
          {errors[key] && (
            <StyledErrorMsg>{errors[key].message || requiredFieldMsg}</StyledErrorMsg>
          )}
        </>
      ) : key === 'phone' ? (
        <StyledPhoneInputWrapper>
          <PhoneInput
            country={'pl'}
            inputProps={{ name: key }}
            {...register(key, { required })}
            onChange={(value) => setValue(key, value)}
            inputClass={`input ${errors[key] && 'form__text-input-error'}`}
            buttonClass="area-code-button"
          />
          {errors[key] && (
            <StyledErrorMsg>{errors[key].message || requiredFieldMsg}</StyledErrorMsg>
          )}
        </StyledPhoneInputWrapper>
      ) : type === 'date' ? (
        <DatePicker
          required={required}
          name="date"
          onChange={setDate}
          value={date}
          monthPlaceholder="Select Date"
          dayPlaceholder=""
          yearPlaceholder=""
        />
      ) : (
        <>
          <input
            {...register(key.replaceAll("'", '`'), { required })}
            className={`form__text-input ${errors[key] && 'form__text-input-error'}`}
          />
          {errors[key] && (
            <StyledErrorMsg>{errors[key].message || requiredFieldMsg}</StyledErrorMsg>
          )}
        </>
      )}
    </StyledListElem>
  )

  const renderQuestionsForms = useCallback(
    ({
      id: answerChoiceId,
      body,
      required,
      type,
      choices,
      single_answer: isSingleAnswerRequired,
      ...rest
    }) => {
      const inputNameKey = `${questionsPreInputName}${answerChoiceId}`

      const renderSwitch = (switchType) => {
        switch (switchType) {
          case 'boolean':
            return (
              <>
                <div className="form__radio-btns-wrapper">
                  <input
                    type="radio"
                    id={`radio-one_${inputNameKey}`}
                    value={true}
                    {...register(inputNameKey, { required })}
                  />
                  <label htmlFor={`radio-one_${inputNameKey}`}>Yes</label>
                  <input
                    type="radio"
                    id={`radio-two${inputNameKey}`}
                    value={false}
                    {...register(inputNameKey, { required })}
                  />
                  <label htmlFor={`radio-two${inputNameKey}`}>No</label>
                </div>
                {errors[inputNameKey] && (
                  <StyledErrorMsg>
                    {errors[inputNameKey].message || requiredFieldMsg}
                  </StyledErrorMsg>
                )}
              </>
            )
          case 'multiple_choice':
            return (
              <div className="form__checkbox-btns-wrapper">
                {choices?.map((item) =>
                  renderCheckBoxInput({ ...item, inputNameKey, isSingleAnswerRequired }),
                )}
                {errors[inputNameKey] && (
                  <StyledErrorMsg>
                    {errors[inputNameKey].message || requiredFieldMsg}
                  </StyledErrorMsg>
                )}
              </div>
            )
          case 'dropdown':
            return (
              <>
                <AsyncSelect
                  className="form__dropdown-container"
                  classNamePrefix="form__dropdown"
                  placeholder="Select an option..."
                  cacheOptions
                  defaultOptions
                  loadOptions={(inputValue, callback) =>
                    callback(getRenamedKeysForDropdown(choices))
                  }
                  onChange={(e) => handleDropdownSelect(e, inputNameKey, required, choices)}
                />
                {/* Fake input for showing error if exists */}
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  className="form__fake-input-for-select"
                  {...register(inputNameKey.replaceAll("'", '`'), { required })}
                />

                {errors[inputNameKey] && (
                  <StyledErrorMsg>
                    {errors[inputNameKey].message || requiredFieldMsg}
                  </StyledErrorMsg>
                )}
              </>
            )
          default:
            return (
              <>
                <input
                  {...register(inputNameKey.replaceAll("'", '`'), {
                    required,
                    ...typeBasedValidation(type),
                  })}
                  className={`form__text-input ${errors[inputNameKey] && 'form__text-input-error'}`}
                />
                <StyledErrorMsg>
                  {errors[inputNameKey] && (errors[inputNameKey].message || requiredFieldMsg)}
                </StyledErrorMsg>
              </>
            )
        }
      }

      return (
        <StyledListElem key={inputNameKey}>
          <StyledLabel htmlFor={inputNameKey}>
            {body} {required && renderRequiredInputStar}
          </StyledLabel>
          {renderSwitch(type)}
        </StyledListElem>
      )
    },
    [errors, handleDropdownSelect, register, renderCheckBoxInput],
  )

  return (
    <Layout shouldNavigationStartTransparent={false}>
      <CareerHero title={jobTitle} location={jobLocation} />
      <StyledApplyFormWrapper ref={formRef}>
        <React.Suspense
          fallback={
            <StyledLoadingIndicatorWrapper>
              <IconLoading />
            </StyledLoadingIndicatorWrapper>
          }
        >
          {!!apiData ? (
            <form onSubmit={handleSubmit(onSubmit)} className="form-content">
              {coreInputFieldsData?.map(renderCoreInputFields)}
              {apiData?.form_fields?.map(renderFormFields)}
              {apiData?.questions?.map(renderQuestionsForms)}
              {loading ? (
                <aside className="form-content__sended-loading-container">
                  <IconLoading />
                </aside>
              ) : (
                <>
                  <Button variant="filled" type="submit">
                    SEND YOUR APPLICATION
                  </Button>
                  {isPostingDataError && (
                    <StyledAxiosError error>
                      Something went wrong and your application was not submitted. Please try again.
                      If problem still exists send us email at{' '}
                      <a href="mailto:careers@10clouds.com">careers@10clouds.com</a>
                    </StyledAxiosError>
                  )}
                </>
              )}
            </form>
          ) : isFetchError ? (
            <StyledAxiosError margin>
              Something went wrong and we could not load form try to reload page, if error still
              exists send us email with your CV and position you want to apply at{' '}
              <a href="mailto:10clouds@jobs.workablemail.com">hr@10clouds.com</a>
            </StyledAxiosError>
          ) : (
            <StyledLoadingIndicatorWrapper>
              <IconLoading />
            </StyledLoadingIndicatorWrapper>
          )}
        </React.Suspense>
      </StyledApplyFormWrapper>
    </Layout>
  )
}

export function Head({ location, data: { workableJob } }) {
  const {
    careerAdPage: { seoDescription },
  } = useCareer()
  const { title } = workableJob

  return (
    <SEO
      location={location}
      title={title}
      description={seoDescription.replace(POSITION_PLACEHOLDER, title)}
    />
  )
}

ApplyForm.propTypes = {
  data: propTypes.object.isRequired,
}

export default ApplyForm
