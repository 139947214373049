import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../../../features/layout'
import { useCareer } from 'hooks/graphql/page'
import CareerHero from '../CareerHero'
import { POSITION_PLACEHOLDER } from '../../constants'
import StyledThankYouPage, { StyledBtnWrapper, StyledCareerVideo } from './styled'
import SurveyForm from '../ThankYourForm'
import Modal from '../../../../components/Modal'
import Button from '../../../../components/Buttons/index'

import terracePartyVideo from '../../../../../static/videos/10Clouds-SUMMER-2021-Terrace-Party_1080p.mp4'
import 'hooks/graphql/others'
import { SEO } from 'components/Seo'

export const query = graphql`
  query CareerThankYouQuery($id: String!) {
    ...workableJob
  }
`

const ThankYou = ({ data }) => {
  const { title: jobTitle, location: jobLocation } = data?.workableJob
  let {
    careerAdPage: { seoDescription },
  } = useCareer()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSurveySent, setIsSurveySent] = useState(false)
  seoDescription = seoDescription.replace(POSITION_PLACEHOLDER, jobTitle)

  return (
    <Layout>
      <CareerHero title={jobTitle} location={jobLocation}>
        <StyledThankYouPage className="thank-you-content" isSurveySent={isSurveySent}>
          <header>
            <h1 className="thank-you__title">THANK YOU FOR YOUR APPLICATION </h1>
            <div className="thank-you__video-wrapper">
              <StyledCareerVideo controls controlsList="nodownload" autoPlay muted>
                <source src={terracePartyVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </StyledCareerVideo>
            </div>

            <StyledBtnWrapper>
              {isSurveySent ? (
                'Thank you for your feedback'
              ) : (
                <Button
                  onClick={() => setIsModalVisible(true)}
                  variant="filled"
                  className="thank-you__survey-button"
                >
                  Help us Improve
                </Button>
              )}
            </StyledBtnWrapper>
            <Modal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)}>
              <SurveyForm
                onClick={() => setIsModalVisible(false)}
                setIsSurveySent={setIsSurveySent}
                isFullscreen={true}
              />
            </Modal>
          </header>
        </StyledThankYouPage>
      </CareerHero>
    </Layout>
  )
}

export function Head({ location, data: { workableJob } }) {
  const {
    careerAdPage: { seoDescription },
  } = useCareer()
  const { title } = workableJob

  return (
    <SEO
      location={location}
      title={title}
      description={seoDescription.replace(POSITION_PLACEHOLDER, title)}
      index={false}
      follow={false}
    />
  )
}

export default ThankYou
