import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from '../../../../../constants/style'

import logo from '../../../../../static/images/10c-logo/10clouds-logo.svg'
import {
  horizontalLayoutPadding,
  horizontalLayoutPaddingMobile,
  horizontalPaddingDesktop,
} from '../commonLayoutStyle'
import { NavLink } from '../../../../components/Link'
import clutchStars from '../../../../../static/images/stars-4-5.svg'

import { legal, social } from '../../../../../constants/links'
import locations from '../../../../../constants/locations'
import contact from '../../../../../constants/contact'
import { useFooterAwardsQuery, useFooterLinksQuery } from 'hooks/graphql/footer'
import FooterLocations from './components/FooterLocations'
import FooterContact from './components/FooterContact'
import FooterAward from './components/FooterAward'
import FooterNav from './components/FooterNav'

const Footer = styled('footer')`
  display: flex;
  flex-wrap: wrap;
  background: linear-gradient(
    ${colors.backgroundGradients.dark.start},
    ${colors.backgroundGradients.dark.end}
  );
  padding-top: 4.6875rem;
  font-family: Roboto-Regular, sans-serif;

  h3 {
    color: #fff;
    padding: 0;
    font-size: 1rem;
    line-height: 1.75rem;
    font-family: Roboto-Regular, sans-serif;
  }

  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.16px;
  }

  a {
    font-family: Roboto-Regular, sans-serif;
    &:hover {
      color: ${colors.secondary};
    }
  }

  .logo {
    img {
      height: 26px;
    }
  }

  section {
    display: flex;
    width: 49%;
    color: #9a99b2;

    &.contact-awards {
      flex-direction: column;
      width: 33.33%;
      padding-right: 1rem;

      &::before {
        content: '';
        width: 3.5rem;
        height: 0.25rem;
        background-color: ${colors.primary};
        margin-bottom: 3.5rem;
      }
    }

    &.links-container {
      display: grid;
      width: 66.66%;
      padding-left: 1rem;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 2rem;
    }
  }

  address {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.16px;

    &.footer__address {
      margin-bottom: 3.5rem;
    }

    .link {
      text-transform: none;
    }
  }

  .link {
    text-transform: capitalize;

    &:hover {
      color: #fff;
    }
  }

  .awards {
    overflow: hidden;

    h3 {
      margin-bottom: 1rem;
    }

    ul {
      padding: 0;
    }
  }

  .locations-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 2rem;
  }

  .locations {
    display: flex;
  }

  .social {
    justify-content: flex-end;
    a {
      padding: 0.25rem 0.75rem 0.7rem;
    }

    .social-link-description {
      font-size: 0;
    }
  }

  .footer-row {
    padding: 0 ${horizontalLayoutPadding} 3rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__line::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: ${colors.purpleGray};
      opacity: 0.5;
    }
  }

  .rating__link-wrapper {
    display: flex;
    width: 100%;

    .rating__link-image-wrapper {
      margin-right: 1.5rem;

      img {
        width: 6rem;
        object-fit: contain;
      }
    }
  }

  .legal {
    justify-content: flex-end;
    text-align: right;
    flex-wrap: wrap;
    gap: 0.25rem 2rem;

    a {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.16px;
      color: ${colors.grayLight};
    }
  }

  .footer_section--wide {
    flex-basis: 70%;
  }

  .footer_section--narrow {
    flex-basis: 29%;
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    section {
      width: 100%;
    }

    .footer-row {
      padding-left: ${horizontalPaddingDesktop};
      padding-right: ${horizontalPaddingDesktop};
    }

    section {
      &.contact-awards {
        padding-right: 0.75rem;
        width: 50%;
      }

      &.links-container {
        width: 50%;
        padding-left: 0.75rem;
        grid-template-columns: 1fr 1fr;
        column-gap: 1.5rem;
        row-gap: 2rem;
      }
    }

    .locations-container {
      column-gap: 1.5rem;
    }

    .footer-row {
      &.rating-legal-container {
        display: block;

        .rating {
          margin-bottom: 2rem;
        }

        .legal {
          text-align: left;
          justify-content: flex-start;

          & > :nth-child(1),
          & > :nth-child(2) {
            margin-right: 10vw;
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .links-container a,
    .legal a {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .locations {
      flex-direction: column;
    }

    .footer-row {
      padding-left: ${horizontalLayoutPaddingMobile};
      padding-right: ${horizontalLayoutPaddingMobile};

      .logo {
        width: 100%;
        margin-bottom: 3rem;
      }

      .footer_section--wide .footer_section--narrow {
        width: 100%;
      }

      .social {
        width: 100%;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 1rem 1.5rem;

        a {
          padding: 0;
          height: 1.5rem;
        }
      }

      .contact-awards {
        padding-right: 0;
        width: 100%;
      }

      .awards {
        margin-bottom: 2.5rem;
      }

      .links-container {
        padding-left: 0;
        width: 100%;
        grid-template-columns: 1fr;
        row-gap: 3.25rem;
      }

      .locations-container {
        grid-template-columns: 1fr 1fr;
        row-gap: 1.5rem;
      }

      &.rating-legal-container {
        .legal {
          & > :nth-child(1),
          & > :nth-child(2) {
            margin-right: 4vw;
          }
        }

        .rating__link-wrapper {
          display: block;

          .rating__link-image-wrapper {
            margin-right: 0;
            margin-bottom: 1.25rem;
          }
        }
      }
    }
  }
`

const FooterComponent = () => {
  const links = useFooterLinksQuery()
  const { awardsSection } = useFooterAwardsQuery()

  const renderSocials = ({ Icon, href, name }, index) => (
    <a target="_blank" rel="noopener noreferrer" href={href} key={`social-link-${index}`}>
      <span className={'social-link-description'}>{name}</span>
      <Icon size="24px" />
    </a>
  )

  const renderPrivacyPolicyTOS = ({ name, url }) => (
    <NavLink to={url} key={`legal-link-${name}`}>
      {name}
    </NavLink>
  )
  return (
    <Footer>
      <div className="footer-row">
        <div className="logo">
          <img src={logo} alt="10clouds logo" />
        </div>
        <section className="social">{social.map(renderSocials)}</section>
      </div>

      <div className="footer-row">
        <section className="contact-awards">
          <div>
            <address className="footer__address">
              10Clouds S.A.
              <FooterContact contact={contact} />
            </address>
          </div>
          <div className="awards">
            <h3>{awardsSection.title}</h3>
            <ul>
              <FooterAward awards={awardsSection.awards} />
            </ul>
          </div>
        </section>
        <section className="links-container">
          <FooterNav links={links} />
        </section>
      </div>

      <div className="footer-row footer-row__line"></div>

      <div className="footer-row">
        <section className="locations-container">
          <FooterLocations locations={locations} />
        </section>
      </div>

      <div className="footer-row rating-legal-container">
        <section className="rating footer_section--wide">
          <a
            href="https://clutch.co/profile/10clouds"
            className="rating__link-wrapper link"
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className="rating__link-image-wrapper">
              <img src={clutchStars} alt="4.5 stars out of 5" />
            </div>
            <p>
              Rated&nbsp;4.9&nbsp;/&nbsp;5.0 by 70 clients for web development, mobile development
              and design services.
            </p>
          </a>
        </section>
        <section className="legal footer_section--narrow">
          {legal.map(renderPrivacyPolicyTOS)}
        </section>
      </div>
    </Footer>
  )
}

export default React.memo(FooterComponent)
