import { FaBehance } from '@react-icons/all-files/fa/FaBehance'
import { FaDribbble } from '@react-icons/all-files/fa/FaDribbble'
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook'
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin'
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'
import { RiInstagramFill } from '@react-icons/all-files/ri/RiInstagramFill'
import { RiYoutubeFill } from '@react-icons/all-files/ri/RiYoutubeFill'

import Agile from '../static/images/icons/agile.svg'
import Blockchain from '../static/images/icons/blockchain.svg'
import ChatGpt from '../static/images/icons/chatGPT.svg'
import Design from '../static/images/icons/design.svg'
import Devops from '../static/images/icons/devops.svg'
import Fintech from '../static/images/icons/fintech.svg'
import Group from '../static/images/icons/group.svg'
import Hiring from '../static/images/icons/hiring.svg'
import Managed from '../static/images/icons/managed.svg'
import Ml from '../static/images/icons/ml.svg'
import Mlops from '../static/images/icons/mlops.svg'
import Mobile from '../static/images/icons/mobile.svg'
import Referral from '../static/images/icons/referral.svg'
import Staff from '../static/images/icons/staff.svg'
import Testing from '../static/images/icons/testing.svg'
import Web from '../static/images/icons/web.svg'

export const siteRoot = '/'
export const caseStudiesUrl = '/case-studies/'
export const howWeWorkUrl = '/how-we-work/'
export const careersUrl = '/careers/'

export const blog = '/blog/'

export const contact = {
  name: 'Contact us',
  url: '/contact-us/',
}

const servicesUrl = '/services/'
export const services = {
  url: servicesUrl,
}

export const navbarLinks = [
  {
    name: 'Services',
    url: servicesUrl,
    numberOfColumns: 2,
    submenuItems: [
      {
        name: 'Web Development',
        link: 'web/',
        Icon: Web,
        services: true,
      },
      {
        name: 'Mobile Development',
        link: 'mobile/',
        Icon: Mobile,
        services: true,
      },
      {
        name: 'Design',
        link: 'design/',
        Icon: Design,
        services: true,
      },
      {
        name: 'FinTech',
        link: 'fintech-solutions/',
        Icon: Fintech,
        services: true,
      },
      {
        name: 'Machine Learning',
        link: 'machine-learning/',
        Icon: Ml,
        services: true,
      },
      {
        name: 'ChatGPT Integration',
        link: 'chat-gpt-integration/',
        Icon: ChatGpt,
        services: true,
      },
      {
        name: 'DevOps',
        link: 'devops/',
        Icon: Devops,
        services: true,
      },
      {
        name: 'MLOps',
        link: 'mlops/',
        Icon: Mlops,
        services: true,
      },
      {
        name: 'Blockchain',
        link: 'blockchain/',
        Icon: Blockchain,
        services: true,
      },
      {
        name: 'NFT Marketplace',
        link: 'white-label-nft-marketplace/',
        Icon: Group,
        services: true,
      },
      {
        name: 'Testing',
        link: 'testing/',
        Icon: Testing,
        services: true,
      },
    ],
  },
  {
    name: 'Case studies',
    url: caseStudiesUrl,
  },
  {
    name: 'How we work',
    url: howWeWorkUrl,
    numberOfColumns: 1,
    submenuItems: [
      {
        name: 'Product Delivery',
        link: 'how-we-work/',
        Icon: Managed,
        services: false,
      },
      {
        name: 'Staff Augmentation',
        link: 'staff-augmentation/',
        Icon: Staff,
        services: true,
      },
      {
        name: 'Agile Playbook',
        link: 'how-we-work/10c-agile-product-delivery-method',
        Icon: Agile,
        services: false,
      },
    ],
  },
  {
    name: 'Careers',
    url: careersUrl,
    numberOfColumns: 1,
    submenuItems: [
      {
        name: "We're hiring",
        link: 'careers/',
        Icon: Hiring,
        services: false,
      },
    ],
  },
  {
    name: 'Blog',
    url: blog,
    targetBlank: true,
  },
]

export const footerLinks = {
  Services: {
    header: { name: 'Services', url: servicesUrl },
  },
  CaseStudies: {
    header: { name: 'Case studies', url: caseStudiesUrl },
  },
  Company: {
    header: { name: 'Company' },
    entries: [
      { name: 'How we work', url: howWeWorkUrl },
      { name: 'Banking of the future', url: 'https://10clouds.com/banking-of-the-future' },
      { name: 'Resources', url: '/resources/' },
      { name: 'Blog', url: blog },
      { name: 'Careers', url: '/careers/', label: "We're hiring" },
      { name: 'Contact us', url: contact.url },
      { name: 'PARP', url: '/parp/' },
    ],
  },
  Technology: {
    header: { name: 'Technologies' },
  },
}

export const social = [
  {
    Icon: FaLinkedin,
    href: 'https://www.linkedin.com/company/10clouds-com',
    name: 'Linkedin account',
  },
  { Icon: FaTwitter, href: 'https://twitter.com/10clouds', name: 'Twitter account' },
  { Icon: FaFacebook, href: 'https://www.facebook.com/10Clouds', name: 'Facebook fanpage' },
  { Icon: RiInstagramFill, href: 'https://www.instagram.com/10clouds/', name: 'Instagram account' },
  { Icon: FaBehance, href: 'https://www.behance.net/10Clouds', name: 'Behance account' },
  { Icon: FaDribbble, href: 'https://dribbble.com/10clouds', name: 'Dribble account' },
  { Icon: RiYoutubeFill, href: 'https://www.youtube.com/c/10clouds', name: 'YouTube channel' },
]

export const legal = [
  { name: 'Terms of service', url: '/terms-of-service/' },
  { name: 'Newsletter terms & regulations', url: '/newsletter-terms-and-regulations/' },
  { name: 'Privacy policy', url: '/privacy-policy/' },
]
