import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '../../features/layout'
import { NavLinkButton } from '../../components/Link'
import { externalContentStyle, breakpoints } from '../../../constants/style'
import { useCareer } from 'hooks/graphql/page'
import CareerHero from './components/CareerHero'
import { addBlankToUrls } from '../../utils/html'
import { POSITION_PLACEHOLDER } from './constants'
import { extractSalaryFromBenefits } from '../../components/sections/JobOffers/utils/jobUtils'
import 'hooks/graphql/others'
import { SEO } from 'components/Seo'

export const query = graphql`
  query CareerPageQuery($id: String!) {
    ...workableJob
  }
`

const StyledCareerAdWrapper = styled('section')`
  background-color: #f6f6f6;
  padding-bottom: 5rem;
  * {
    font-family: 'BioSans-Regular', 'Roboto-Regular', sans-serif;
  }

  .career-ad__content {
    background: white;
    width: 70%;
    padding: 5rem;
    margin: 0 auto;
    position: relative;
    margin-top: -10rem;
    ${externalContentStyle};

    h1,
    h2,
    h3 {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }

    p,
    ul,
    ol {
      margin-bottom: 1.5rem;
    }

    p,
    li {
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    .career-ad__content {
      width: 100%;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .career-ad__content {
      margin-top: -7rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding-bottom: 2rem;
    .career-ad__content {
      padding: 1rem;
      margin-top: -10rem;
    }
  }
`

const CareerPage = ({ data: { workableJob }, location: browserLocation }) => {
  const { description, requirements, benefits, title, location } = workableJob
  const {
    careerDictionary: { applyLabel },
  } = useCareer()

  // If you want link to workable form, import from workable application_url - and swap the link below
  const applyFormLink = `${browserLocation.pathname}apply/`

  return (
    <Layout>
      <CareerHero title={title} location={location} />
      <StyledCareerAdWrapper>
        <div className="career-ad__content">
          {description && (
            <div dangerouslySetInnerHTML={{ __html: addBlankToUrls(description) }}></div>
          )}
          {requirements && (
            <div dangerouslySetInnerHTML={{ __html: addBlankToUrls(requirements) }}></div>
          )}
          {benefits && <div dangerouslySetInnerHTML={{ __html: addBlankToUrls(benefits) }}></div>}
          <NavLinkButton to={applyFormLink} variant="filled">
            {applyLabel}
          </NavLinkButton>
        </div>
      </StyledCareerAdWrapper>
    </Layout>
  )
}

export function Head({ location, data: { workableJob } }) {
  const {
    careerAdPage: { seoDescription },
  } = useCareer()
  const { title, description, created_at, employment_type, benefits } = workableJob

  const indexOfNetBenefits = extractSalaryFromBenefits(benefits)?.indexOf('net')
  const salaryRange = extractSalaryFromBenefits(benefits)?.slice(4, indexOfNetBenefits)
  const twoMontsAdvancedCreatedAtDate = new Date(
    new Date(created_at).setMonth(new Date(created_at).getMonth() + 2),
  ).toDateString()
  const indexOfDotDescription = description?.indexOf('.')
  const extractedDescription = description?.slice(3, indexOfDotDescription)
  const seoImage = { asset: { url: 'https://10clouds.com/images/hiring-seo-image.jpg' } }

  return (
    <SEO
      location={location}
      title={title}
      description={seoDescription.replace(POSITION_PLACEHOLDER, title)}
      image={seoImage}
    >
      {/* https://developers.google.com/search/docs/advanced/structured-data/job-posting */}
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'JobPosting',
          title: title,
          description: `<p>${extractedDescription}</p>`,
          identifier: {
            '@type': 'PropertyValue',
            name: '10Clouds',
            value: '1234567',
          },
          datePosted: created_at,
          validThrough: twoMontsAdvancedCreatedAtDate,
          applicantLocationRequirements: {
            '@type': 'Country',
            name: location.country,
          },
          jobLocationType: 'TELECOMMUTE',
          employmentType: employment_type,
          hiringOrganization: {
            '@type': 'Organization',
            name: '10Clouds',
            sameAs: 'http://10clouds.com',
            logo: 'https://10clouds.com//favicon-32x32.png?v=49a3c48f999c570021459142bc30db90',
          },
          baseSalary: {
            '@type': 'MonetaryAmount',
            currency: 'PLN',
            value: {
              '@type': 'QuantitativeValue',
              value: salaryRange,
              unitText: 'MONTH',
            },
          },
        })}
      </script>
    </SEO>
  )
}

export default CareerPage
