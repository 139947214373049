const coreInputFieldsData = [
  {
    inputName: 'firstname',
    label: 'First Name',
    options: {
      required: true,
    },
    type: 'text',
  },
  {
    inputName: 'lastname',
    label: 'Last Name',
    options: {
      required: true,
    },
    type: 'text',
  },
  {
    inputName: 'email',
    label: 'Email',
    options: {
      required: true,
      pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    type: 'email',
  },
];

export default coreInputFieldsData;
