import styled, { css } from 'styled-components';

import { colors, breakpoints, externalContentStyle } from '../../../../../constants/style';

const defaultFont = css`
  font-family: "BioSans-SemiBold", sans-serif;
`;

const closeModal = css`
  .close {
    top: 60px;
    right: 40px;
    background: #302152;
    position: fixed;
    width: 35px;
    height: 35px;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      height: 1.7px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #a7a7a7;
      transition: background 0.2s ease;
    }
    &::after {
      transform: rotate(45deg);
    }
    &::before {
      transform: rotate(-45deg);
    }
  }
`;

const StyledMainSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: fill;
  ${({ isFullscreen }) => isFullscreen && 'justify-content: center'};
  width: ${({ isFullscreen }) => (isFullscreen ? '100%' : '90%')};
  background-color: ${colors.backgroundLight};
  height: ${({ isFullscreen }) => (isFullscreen ? '100vh' : '60vh')};
  overflow: auto;
  padding: 4rem 0;
  border-radius: 8px;
  ${externalContentStyle};
  ${closeModal}

  svg {
    align-self: center;
  }

  article {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    margin: 2rem 0;
    box-shadow: 4px 24px 40px rgb(0 0 0 / 5%);
  }

  main {
    display: flex;
    justify-content: space-around;
    max-width: 40vw;
    margin: 1rem auto;
  }

  label {
    margin-bottom: 15px;
    text-align: center;
  }

  .option {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
  button {
    width: 100%;
  }

  .form__input-required {
    color: ${colors.error};
    ${defaultFont}
    margin-bottom: 0;
  }

  h1,
  h2,
  h3 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    text-align: center;
  }

  p,
  ul,
  ol {
    margin-bottom: 1.5rem;
  }

  p,
  li {
    font-size: 1rem;
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    .form-content {
      padding: 1rem;
      width: 80%;
    }
    header {
      margin-top: 15vh;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .form-content {
      margin-top: -7rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    height: 100vh;
    width: 100%;
    border-radius: 0;

    padding-bottom: 2rem;

    main {
      max-width: 60vw;
    }

    h1 {
      font-size: 2rem;
      padding: 4rem 0;
    }
    .form-content {
      padding: 1rem;
      width: 100%;
    }
  }
`;

export const StyledLoadingIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledPostErr = styled.div`
  text-align: center;
  color: ${colors.error};
`;

export default StyledMainSection;
